<template>
  <div class="assets-revert-list">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item v-model="searchType"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
        <van-icon name="scan"
                  class="scan-icon"
                  @click.stop="scanAdd" />
      </div>
      <div class="filter">
        <div class="filter-item"
             @click="startDateClick">
          <span>{{ startDateComputed }}</span>
          <van-icon name="arrow-down" />
        </div>

        <div class="filter-item"
             @click="statusClick">
          <span>{{ statusComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
      <van-calendar v-model="showStartDate"
                    :min-date="minDate._d"
                    :formatter="startDateFormatter"
                    @confirm="startDateConfirm"
                    :show-confirm="false" />
      <van-popup v-model="showStatus"
                 round
                 position="bottom">
        <custom-popup title="状态"
                      value-key="label"
                      :columns="statusColumns"
                      @cancel="showStatus = false"
                      @confirm="statusConfirm"></custom-popup>
      </van-popup>
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  ref="list"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id">
            <item-card :title="item.lendingNumber">
              <div class="content">
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">单位:</span>
                    <span>{{ item.organizationName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">借用人:</span>
                    <span>{{ item.applicantName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="">
                    <span class="name">借用申请时间:</span>
                    <span>{{ item.creationTime }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="">
                    <span class="name">预计归还日期:</span>
                    <span>{{ item.lendingEndDate }}</span>
                  </div>
                </div>
              </div>
              <template #footer>
                <div class="action">
                  <van-button class="detail-btn"
                              type="info"
                              size="small"
                              round
                              @click="revert(item.id)">归还</van-button>
                </div>
              </template>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
    <!-- <div class="footer">
      <van-button class="add-btn" type="info" round @click="addWarehousing">新增入库</van-button>
    </div> -->
  </div>
</template>

<script>
import "@/assets/less/views/assets/assetsRevertList.less";
import { SUCCESS_CODE } from "@/apis/notification";
import { find } from "lodash-es";
import Filter from "ldap-filters";
import wxapi from "@/lib/wechat";
import customPopup from "@/components/customPopup.vue";

export default {
  components: { customPopup },
  name: "AssetsRevertList",
  data() {
    return {
      searchType: "lendingNumber",
      searchTypeOptions: [
        { text: "出库单号", value: "lendingNumber" },
        { text: "单位", value: "organizationName" }
      ],
      searchContent: "",

      filter: {
        startDate: "",
        status: ""
      },
      minDate: this.$moment().subtract(0.5, "y"),

      showStartDate: false,
      showStatus: false,
      statusColumns: [
        { label: "全部状态", value: "" },
        { label: "已借用", value: "SUCCESS" },
        { label: "已归还", value: "GIVE_BACK" }
      ],

      list: [],
      listStatus: {
        loading: true,
        finished: false
      },
      page: {
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  computed: {
    startDateComputed() {
      if (!this.filter.startDate) return "起始时间";
      return this.filter.startDate;
    },
    statusComputed() {
      let item = find(this.statusColumns, item => item.value == this.filter.status);
      return item ? item.label : "状态";
    }
  },
  methods: {
    getAssetLendingsApi(params) {
      return this.$api.getAssetLendings(params);
    },
    getAssets(code) {
      let params = { pageNumber: 0, pageSize: 10 };
      params.query = `(barcode=${code})`;
      return this.$api.getAssetsByQuery({ params });
    },
    async getAssetsIdLendsApi(id) {
      return this.$api.getAssetsIdLends({ placeholder: { id } });
    },
    async getAssetRevertList(sign = false) {
      let params = {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize
      };
      params.query = this.getQuery();
      let ret = await this.getAssetLendingsApi({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    getQuery() {
      let filters = [];
      if (this.filter.startDate) {
        let startDate = Filter.attribute("creationTime").gte(this.filter.startDate + " 00:00:00");
        filters.push(startDate);
      }
      if (!this.filter.status) {
        let s1 = Filter.attribute("status").equalTo("SUCCESS");
        let s2 = Filter.attribute("status").equalTo("GIVE_BACK");
        let filter = Filter.OR([s1, s2]);
        filters.push(filter);
      } else {
        filters.push(Filter.attribute("status").equalTo(this.filter.status));
      }

      if (this.searchContent) {
        filters.push(Filter.attribute(this.searchType).contains(this.searchContent));
      }
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    startDateFormatter(day) {
      if (this.endDateComputed == "结束时间") return day;
      if (day.date.getTime() - this.$moment(this.endDateComputed).add(1, "d") >= 0) {
        day.type = "disabled";
      }
      return day;
    },
    endDateFormatter(day) {
      if (this.startDateComputed == "结束时间") return day;
      if (day.date.getTime() - this.$moment(this.startDateComputed).subtract(1, "d") <= 0) {
        day.type = "disabled";
      }
      return day;
    },
    onSearch() {
      this.getInitialData();
    },
    startDateClick() {
      this.showStartDate = true;
    },
    statusClick() {
      this.showStatus = true;
    },
    startDateConfirm(arg) {
      this.filter.startDate = this.$moment(arg).format("YYYY-MM-DD");
      this.showStartDate = false;
      this.getInitialData();
    },
    statusConfirm(arg) {
      this.filter.status = arg.value;
      this.showStatus = false;
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getAssetRevertList();
    },
    revert(id) {
      this.$router.push({
        name: "assetsRevertDetail",
        params: {
          id
        }
      });
    },
    getCode() {
      return new Promise(resolve => {
        wx.scanQRCode({
          needResult: 1,
          scanType: ["qrCode", "barCode"],
          success: res => {
            var code = res.resultStr.split(",").splice(-1)[0];
            resolve(code);
          }
        });
      });
    },
    scanAdd() {
      this.getCode().then(async code => {
        let ret1 = await this.getAssets(code);
        if (ret1.code != SUCCESS_CODE) return;
        let ret2 = await this.getAssetsIdLendsApi(ret1.data[0].id);
        if (ret2.code != SUCCESS_CODE) return;
        let data = ret2.data.filter(item => item.assetLending.usingStatus == "LENDING" && !item.giveBack);
        if (!data.length) {
          return this.warning("暂无信息");
        }
        let id = data[0].assetLending.id;
        this.$router.push({
          name: "assetsRevertDetail",
          params: {
            id
          }
        });
      });
    },
    onLoad() {
      this.getAssetRevertList(true);
    },
    init() {
      this.page = {
        pageNumber: 0,
        pageSize: 10
      }
      wxapi.ready(["scanQRCode"]).then(res => {
        console.log(res);
      });
      this.getAssetRevertList();
    }
  },
  mounted() {
    this.init()
  },
  created() { }
};
</script>

<style></style>
